/*
 * Group join button
 */
jQuery(document).ready(function($) {
	'use strict';

	var $join_button = $('.groupsgrid-actions > .generic-button:not(.pending) a.group-button:not(.pending)');

	var initSpinner = function() {
		$join_button.on('click', function() {
			$(this).addClass('-spinner');
		});
	};

	initSpinner();

	$(document).ajaxComplete(function() {
		setTimeout( function() {
			$join_button.filter('.membership-requested').addClass('pending').closest('.generic-button').addClass('pending');
			initSpinner();
		}, 500 );
	});

});
