/*
 * Group switcher
 * 1.0.0
 */
jQuery(document).ready(function($) {
	'use strict';

	$('.group-switcher > .switcher').change(function() {
		var $this = $(this);
		var href = $this.val();
		window.location.href = href;
	});
	
});
