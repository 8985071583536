/*
 * Accordion
 * 1.0.0
 */
jQuery(document).ready(function($) {
	'use strict';
	
	$('.accordion-container').accordion({
		'collapsible': true,
		'active': false,
		'heightStyle': 'content',
		'icons': {
			'header': 'fa fa-caret-right',
			'activeHeader': 'fa fa-caret-down'
		}
	});
	
});
