/*
 * Collapsible
 * 1.0.0
 */
jQuery(document).ready(function($) {
	'use strict';

	$('.collapsible-container').each(function() {
		var $container = $(this);
		var $primary = $container.find('.collapsible-primary');
		var $secondary = $container.find('.collapsible-secondary');
		$primary.click(function() {
			if ( $container.hasClass('-active') ) {
				$container.removeClass('-active');
				$secondary.slideUp(300);
			} else {
				$('.collapsible-container.-active').removeClass('-active').find('.collapsible-secondary').slideUp(300);
				$container.addClass('-active');
				$secondary.slideDown(300, 'swing', function() {
					$.smoothScroll({
						scrollTarget: $container,
						offset: -95,
					});
				});
			}
			return false;
		});
	});
	
});
