import $ from 'jquery'; // jQuery is external

$(document).ready(function() {

    var $headingRow = $('.membership-chart > tbody > tr.heading-row');
    $headingRow.each(function(e) {
        var $this = $(this);
        var alt = false;
        if (e % 2 == 0) {
            alt = true;
            $this.addClass('-alt');
        }
        var $subItems = $this.nextUntil('[class~="heading-row"]');
        if ( $subItems.length > 0 ) {
            $this.addClass('-haschildren');
            if ( alt ) {
                $subItems.addClass('-alt');
            }
            $this.click(function() {
                $subItems.toggleClass('-show');
                $this.toggleClass('-show');
            });
        }
    });

    var $helpLink = $('.membership-chart .help-link');
    $helpLink.each(function(e) {
        var $this = $(this);
        var $helpText = $this.next('.help-text').remodal({
            hashTracking: false,
            closeOnOutsideClick: true,
            closeOnEscape: true
        });
        $this.on('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            $helpText.open();
        });
    });

});
