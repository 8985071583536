/**
 * Add confirmation to subscription cancellation
 */

jQuery(document).ready(function($) {
	'use strict';
	
	$('body.woocommerce-account.logged-in .subscription_details .button.suspend, body.woocommerce-account.logged-in .subscription_details .button.cancel').click(function(e) {
		if ( !confirm('Are you sure you want to ' + $(this).text().toLowerCase() + ' for this subscription?') ) {
			e.preventDefault();
		} 
	});
	
});
